.product-name {
  font: normal normal medium 16px/19px Lato;
  letter-spacing: 1.6px;
  color: #333333;
  line-height: 19px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.product-price {
  text-align: left;
  font: normal normal 900 14px/22px Lato;
  letter-spacing: 1.8px;
  color: #525252;
  text-transform: uppercase;
  opacity: 1;
}
.product-page-product-card-action-area {
  height: 100% !important;
}
.product-page-product-card-action-area:hover {
  background-color: transparent !important;
  color: inherit !important;
}
.product-page-product-card-container {
  .images {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    .images {
      height: 100%;
      width: 100%;
    }
    .product-name {
      font-size: 8px;
      letter-spacing: 0;
    }
    .product-price {
      font-size: 12px;
    }
  }
}
.product-list-page-heart-icon-container {
  position: absolute;
  bottom: 20%;
  background: #fff;
  right: 0;
}
.product-list-page-heart-icon-button:hover {
  border: none !important;
}
.react-slider__imgZoom {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(20px);
  width: 100% !important;
  height: 100% !important;
  background-repeat: no-repeat;
  border: thin solid #ccc;
  opacity: 0;
  z-index: -99;
  transition: opacity 0.3s ease-in-out;
}

.slider-wrapper {
  &.single-slide {
    .react-slider__btnNext {
      display: none;
    }
    .react-slider__btnPrev {
      display: none;
    }
  }
}

.react-slider__ul li.active img {
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #555;
}
.react-slider__areaZoom img {
  z-index: 2;
  position: relative;
}
.react-slider__ul {
  padding: 0px;
}
.react-slider__ul li {
  position: relative;
  width: 70px;
  cursor: pointer;
  padding: 0;
  list-style: none;
  margin: 1%;
  flex: 0 0 14%;
}
.react-slider__lens {
  position: absolute;
  z-index: 3;
  border: 1px solid #d4d4d4;
  width: 130px;
  height: 130px;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.react-slider__picture:after{
  content: ""!important
}

.navigation-dropdown-wrapper {
  position: fixed;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 999;
  border-top: 0.5px solid #d6d6d6;

  a {
    color: #000;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  .jewellery-links {
    column-gap: 200px;
    width: 100% !important;

    .catergory-section {
      margin-bottom: 10px;
      .jewellwery-type {
        font: normal normal 900 17px/19px "Afacad Flux", serif;
        letter-spacing: 1.95px;
        color: #333333;
        text-transform: uppercase;
      }

      .jewelleries {
        display: grid;
        grid-column-gap: 1.2rem;
        grid-row-gap: 0.5rem;
        grid-template-columns: auto auto;
        font-family: "Afacad Flux", serif;
        font-size: 16px;
        letter-spacing: 1.3px;
        color: #333333;

        .jewellery-link:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .bestsellers-section {
    max-height: 302px;
    .overlay-text {
      position: relative;
      transform: translate(0, -100%);
      color: #fff;
    }
  }
}

@media screen and (min-width: 1440px) {
  .jewellery-links {
    display: flex;
    justify-content: flex-start;
  }
}

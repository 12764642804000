.personal-details-container {
  .personal-details-form {
    background: #fff;

    .btn-submit {
      letter-spacing: 1.8px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

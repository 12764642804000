.bullions-filter-menu {
  height: 100vh;

  .accordion-title {
    font-style: normal;
    font-weight: normal;
    font-stretch: bold;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Lato';
    letter-spacing: 2.1px;
    color: #333333;
  }

  .black-checkbox {
    color: #000;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
    margin: 0;
}
.page-header {
  padding: 9px 40px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  width: 100%;
  height: 120px;
  z-index: 5;
}

.header-content {
  position: relative;
  background-color: #ffffff;
  height: 100%;

  .menu-open {
    width: 29px;
  }

  .menu-close {
    width: 15.15px;
    height: 15.22px;
  }

  #jewellery-link {
    min-height: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  .logo {
    width: 69px;
    height: 39px;
    opacity: 1;
    background-color: #ffffff;

    img {
      position: relative;
      z-index: 1;
      background-color: #ffffff;
    }
  }

  .quick-links-wrapper {
    display: grid;
    height: 100%;
    align-content: center;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: right; 
      float: right;
      li {
        display: inline-block;
        padding: 0 10px;
        font-family:"Afacad Flux", serif;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: #525252;
        text-transform: uppercase;

        a {
          opacity: 1;
          text-decoration: none;
          color: #525252;
        }
      }
    }
  }

  .navbar {
    ul {
      list-style: none;
      padding-left: 0px;
      margin-top: 2rem;

      .menu-link-items {
        display: flex;
        column-gap: 8%;
        text-align: right;
      }

      li {
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        font: normal normal 900 17px/19px "Afacad Flux", serif;
        letter-spacing: 2px;
        color: #672A2F;

        a {
          opacity: 1;
          text-decoration: none;
          color: #672A2F;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .header-icon-list {

    ul {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      li {
        display: inline-block;
        padding: 0 10px;

        img {
          width: 30px;
          height: 30px;
        }
      }
      .heart {
        width: 23.2px;
      }
      .login-icon {
        padding-left: 0 !important;
      }
    }
  }

  .mobile-menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .mobile-menu-overlay .navbar {
    padding-top: 0;
    background: #fff;
    width: calc(100% - 60px);
    height: 100%;
    align-items: flex-start;

    ul {
      margin: 0;

      .header-searchbar-wrapper {
        background-color: #f0eee5;
        height: 60px;
        position: relative;
        display: flex;

        .search-wrapper {
          align-self: center;
          height: 32px;
          display: flex;
        }

        .search-input {
          margin-left: 20px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #707070;
          font-family: "Afacad Flux", serif;
          font-size: 14px;
          letter-spacing: 2.1px;
          height: 100%;
        }

        .search-input::placeholder {
          color: #666666 !important;
        }

        .search-input:focus {
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #707070;
        }

        img {
          width: 19.2px;
          height: 23.19px;
        }

        .search-logo {
          align-self: center;
        }

        .shopping-logo {
          display: inline-grid;
          position: absolute;
          right: 25.5px;
          align-content: center;
          height: 100%;
        }
      }
    }

    ul li {
      line-height: 1;
      margin-bottom: 18px;
      padding-left: 25px;
    }

    ul div.menu-link-label {
      font-size: 17px;
      color: #a4d0fb;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 25px;
    }

    .drawer-header {
      padding-left: 25px;
      font-size: 17px;
      font-weight: 900;
      margin-bottom: 25px;
      font-family: "Afacad Flux", serif;
      margin-top: 28px;
    }

    li a {
      color: #672A2F;
      font-size: 17px;
      letter-spacing: 2.1px;
      font-family: "Afacad Flux", serif;
    }

    ul li.add-toggle-menu-link {
      border-top: 1px solid #a4d0fb;
      padding-top: 24px;
      margin-top: 28px;

      a {
        color: #a4d0fb;
      }
    }
  }

  .close-nav-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }
}
  @media (min-width: 767px) {

    .navbar {
      ul {
        list-style: none;
        padding-left: 0px;
  
        .menu-link-items {
          display: flex;
          column-gap: 8%;
          text-align: right;
        }
  
        li {
          display: inline-block;
          font-size: 16px;
          text-transform: uppercase;
          font: normal normal 900 17px/19px "Afacad Flux", serif;
          letter-spacing: 2px;
          color: #692526;
          position: relative;
          cursor: pointer; 
          padding-bottom: 3px;
  
          a {
            opacity: 1;
            text-decoration: none;
            color:#692526;
            width: 100%;
            height: 100%;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 3px;
            background-color: #672A2F;
            transform-origin: center;
            transition: all 0.2s ease-in-out;
          }
    
          &:hover::after {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

@media (max-width: 767px) {
  .page-header {
    padding: 9px 18px;
    height: 60px;
    display: grid;
    align-content: center;
  }

  .header-icon-list {
    ul {
      padding: 0;
      margin: 0;
      margin-top: 0 !important;
    
    }
  }
}


.your-details-wrapper {
  .your-details-box {
    a {
      text-decoration: underline;
      font: normal normal bold 14px/17px Lato;
      letter-spacing: 2.1px;
      color: #916f25;
      text-transform: uppercase;
    }
  }
}

button {
  background: transparent;
  border: 1px solid #000000;
  padding: 0 17px;
  height: 45px;
  opacity: 1;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: #000000;
  font-size: 12px;
  transition: 0.6s;
}

button:hover {
  background-color: #672A2F !important;
  color: #ffffff !important;
  border: 1px solid #672A2F !important;
}

input {
  background: transparent;
  height: 45px;
  border: 1px solid #000000;
  // opacity: 0.34;

  letter-spacing: 0px;
  padding-inline: 12px;
}

input::placeholder {
  color: #b7b7b7;
}

hr {
  width: 100%;
  border: 0.5px solid #d6d6d6;
}

.arrow-icon-generic {
  background: none;
  border: none;
  padding: 0;
}

.arrow-icon-generic:disabled {
  cursor: not-allowed;
  background-color: #b7b7b7;
  opacity: 0.5;
  &:hover {
    background-color: #b7b7b7 !important;
    color: #000000 !important;
  }
}

.image-slider-navigation-icon {
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 39px;
}

.image-slider-navigation-icon button:hover {
  background: transparent;
  border: none;
}

.carousel-locate-store-btn {
  top: 3307px;
  left: 620px;
  width: 200px;
  height: 45px;
  opacity: 1;
  background-color: #ffffff;
}
.main-body-container {
  min-height: 100%;
}
.section-header {
  font: normal normal 600 32px/43px Playfair Display;
  letter-spacing: 0.48px;
  color: #672a2f;
}
@media (max-width: 767px) {
  .section-header {
    font-size: 24px;
  }
}

// blog and news
.column-news {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.row-news::after {
  content: "";
  clear: both;
  display: table;
}

.image-container-news {
  position: relative;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.gallery-image-news {
  width: 100%;
}

.image-text-news {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
}

.container-blog {
  display: flex;
  align-items: center;
}

.image-container-blog {
  flex: 1;
}

.gallery-image-blog {
  width: 100%;
  height: auto;
}

.text-container-blog {
  flex: 1;
  padding: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container-blog {
    flex-direction: column;
    align-items: stretch;
  }

  .image-container-blog,
  .text-container-blog {
    width: 100%;
  }
}

.icon-space {
  margin-left: 1em;
}
.review-stars {
  display: flex;
  align-items: center;
}
.success-wrapper {
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.dash-wrapper {
  height: 85vh;
}
.no-data {
  display: flex;
  justify-content: center;
}
.pagination {
  padding: 30px 0;
}

.site-logo {
  width: 104px;
  height: 59px;
}

.site-logo-footer {
  width: 98px;
  height: 55px;
}

.left-logo,
.right-logo {
  width: 26px;
  height: 22px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo img,
.chandukaka-image {
  max-height: 100px;
}

// //collage images

// .collage-container {
//   display: grid;
//   grid-template-columns: 2fr 1fr;
//   grid-template-rows: auto;
//   gap: 10px;
//   padding: 20px;
// }

// .collage-item {
//   position: relative;
// }

// .collage-item img {
//   width: 100%;
//   height: auto;
//   display: block;
//   border-radius: 10px;
// }

// .collage-item.large {
//   grid-column: 1 / 2;
//   grid-row: 1 / 3;
// }

// .collage-item.small {
//   grid-column: 2 / 3;
// }

// .collage-item.small:first-of-type {
//   grid-row: 1 / 2;
// }

// .collage-item.small:last-of-type {
//   grid-row: 2 / 3;
// }

// .caption {
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   color: white;
//   background: rgba(0, 0, 0, 0.5);
//   padding: 5px 10px;
//   border-radius: 5px;
// }

// @media (max-width: 768px) {
//   .collage-container {
//     grid-template-columns: 1fr;
//   }

//   .collage-item.large {
//     grid-column: 1 / 2;
//     grid-row: 1 / 2;
//   }

//   .collage-item.small {
//     grid-column: 1 / 2;
//   }

//   .collage-item.small:first-of-type {
//     grid-row: 2 / 3;
//   }

//   .collage-item.small:last-of-type {
//     grid-row: 3 / 4;
//   }
// }
.collage-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 10px;
  padding: 20px;
}

.collage-item {
  position: relative;
}

.collage-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.collage-item.large {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.collage-item.small {
  grid-column: 2 / 3;
}

.collage-item.small:first-of-type {
  grid-row: 1 / 2;
}

.collage-item.small:last-of-type {
  grid-row: 2 / 3;
}

.caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .collage-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .collage-item.large {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .collage-item.small {
    grid-column: 1 / 2;
  }

  .collage-item.small:first-of-type {
    grid-row: 2 / 3;
  }

  .collage-item.small:last-of-type {
    grid-row: 3 / 4;
  }
}

/* Media query for tablet devices */
@media (min-width: 769px) and (max-width: 1024px) {
  .collage-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .collage-item.large {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .collage-item.small {
    grid-column: span 1;
  }

  .collage-item.small:first-of-type {
    grid-row: 2 / 3;
  }

  .collage-item.small:last-of-type {
    grid-row: 2 / 3;
  }
}

/* ImageCollage.css */

/* Ensure images are displayed correctly on smaller screens */
// @media (max-width: 768px) {
//   .order-1 {
//     order: 1;
//   }

//   .order-2 {
//     order: 2;
//   }

//   .mb-2 {
//     margin-bottom: 0.5rem;
//   }
// }

.campaign-container {
  text-align: center;
}

.campaign-container h1 {
  margin-bottom: 20px;
}

.campaign-container p {
  margin-bottom: 40px;
  color: #555;
}

.img-fluid {
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  .site-logo {
    width: 69px;
    height: 39px;
  }
  
  .order-1 {
    order: 1 !important;
  }

  .order-md-1 {
    order: 2 !important;
  }

  .order-md-2 {
    order: 1 !important;
  }

  .mb-2 {
    margin-bottom: 15px;
  }
}
//ImageCollage

.our-campaigns {
  font: normal normal 600 36px / 48px Playfair Display;
  letter-spacing: 0.54px;
  color: #672a2f;
}

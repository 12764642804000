.contact-us {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 30px 120px 10px 120px;
}
.product-main {
  padding: 0 120px;
}
.contact-us-center {
  text-align: -webkit-center;
}

.image-slider-header img {
  padding-right: 10px;
}

.contact-us-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font: normal normal normal 20px/28px "Afacad Flux", serif;
}

.contact-us-container {
  padding: 0px 25% 50px 25%;
}

.form-control-Contact-us {
  display: block;
}

// .text-field {
//     margin: 10px !important;
// }

.text-field-name-contact-us {
  width: 44%;
}

.text-field-email-contact-us {
  //   width: 93% !important;
}
.text-field{
  font-family: "Afacad Flux", serif;
  font-size: '20px';
}
.contact-us-one-line-form-field {
  width: 100%;
}

.term-and-condition-section {
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 7px;
  padding-right: 14px;
}
.checkbox-contact-us-page-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  font: normal normal normal 18px/26px "Afacad Flux", serif !important;

}

.contact-us-one-line-form-field button {
  width: 93%;
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  opacity: 1;
  font: normal normal medium 20px/28px "Afacad Flux", serif;
  letter-spacing: 1.8px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 15px;
}

.decoration-contact-us-underline {
  text-align: left;
  text-decoration: underline;
  font: normal normal medium 20px/28px "Afacad Flux", serif;
  letter-spacing: 0.9px;
  color: #916f25;
}

@media (max-width: 768px) {
  .contact-us {
    padding: 48px 80px 48px 80px;
  }
  .contact-us-container {
    padding: 50px 40px 50px 40px;
  }
  .contact-us-description {
    text-align: center;
    font: normal normal normal 20px/28px "Afacad Flux", serif;
    letter-spacing: 0px;
    color: #666666;
  }

  .decoration-contact-us-underline {
    text-align: center;
    text-decoration: underline;
    font: normal normal bold 20px/28px "Afacad Flux", serif;
    letter-spacing: 0px;
    color: #916f25;
  }
  .text-field-email-contact-us {
    // width: 90% !important;
  }
}

@media (max-width: 425px) {
  .text-field-name-contact-us {
    width: 90%;
  }
  .contact-us {
    padding: 0;
    padding-bottom: 50px;
  }
  .contact-us-container {
    padding: 50px 2px 50px 2px;
  }
}

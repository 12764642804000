.box-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24;
  width: 40em;
}

.outlined-black {
  border-color: #000 !important;
  color: #000 !important;
  border-radius: 0 !important;
}

@media screen and (max-width: 425px) {
  .box-style {
    width: 100%;
  }
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    position: static !important;
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 30px;

}

.myorder-container {

    .no-data-wrapper {
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .orders-wrapper {
        max-height: 60vh;
        overflow: auto;
    }

    .table-wrapper {
        min-width: 100%;
    }

    .status-btn {
        height: 30px;
        background: #F0EEE5 0% 0% no-repeat padding-box;
        font-size: 12px;
        font-family: Lato;
        font-weight: 600;
        border: none;
        letter-spacing: 1.8px;
        color: #333333;
        text-transform: uppercase;
    }

    .delivery-desc {
        font: normal normal 600 8px/10px Lato;
        letter-spacing: 0px;
        color: #999999;
    }
}

@media (max-width: 767px) {
    .myorder-container {
        width: 100%;
    }
}

// Login page scss 
.promises-section {
  background-color: #672a2f;
  color: white;
  padding-top: 20px;
  text-align: center;
}

.promises-container {
  max-width: 1400px;
  margin: 0 auto;
}

.promise-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.heading-font {
  font: normal normal 600 36px/48px "Vidaloka", serif;
  letter-spacing: 1px;
}
.heading-text{
  font: normal normal normal 20px/28px "Afacad Flux", serif;

}
.content-text {
  font: normal normal normal 16px/22px "Afacad Flux", serif;
  // letter-spacing: 0px;
}

.promises-img {
  max-width: 60px;
  height: auto;
  display: block;
width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.promise-text {
  font-size: 10xpx;
  line-height: 1.2;
  font: normal normal normal 20px/28px "Afacad Flux", serif;
  letter-spacing: 0px;
  white-space: normal;

  word-wrap: break-word;
}

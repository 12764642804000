// .whatsapp-button {
//   position: fixed;
//   bottom: 15px;
//   right: 15px;
//   z-index: 99;
//   background: #39c04c 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 6px #00000029;
//   border-radius: 9999px;
//   width: 2.15rem;
//   height: 2.15rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   -webkit-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
//   -moz-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
//   box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
//   animation: effect 5s infinite ease-in;

//   a {
//     text-decoration: none;
//     color: #ffffff;
//     font: normal normal 600 16px/21px "Vidaloka", serif;
//     padding: 15px;
//     align-items: center;
//     display: flex;
//     gap: 4px;
//   }
// }

footer {
  position: relative;
  // height: 620px;
  color: #333333;
  font-family: "Afacad Flux", serif;
  font-size: 20px;
  background: #f8f6f0 0% 0% no-repeat padding-box;

  .footer-container {
    padding: 48px 120px 5px 120px;

    .logo {
      img {
        width: 98px;
        height: 55px;
      }
    }

    .footer-containt {
      padding-top: 40px;
      letter-spacing: 0px;

      span {
        font-weight: bold;
      }
    }

    .btn.btn-link {
      display: block;
      margin-bottom: 3px;
      padding: 0;
      text-align: left;
      text-decoration: none;
      color: #333333;
    }

    .footer-heading {
      font-weight: bold;
      font-size: 28px;
    }

    .footer-text {
      color: #666666;
    }
    

    .sbscribe-wrapper {
      position: relative;

      .subscribe-btn {
        position: absolute;
        right: 0;

        img {
          width: 16px;
          height: 10px;
          opacity: 1;
        }
      }

      .subscribe-input {
        padding-inline-end: 145px;
      }
    }

    .social-media-wrapper {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      width: 180px;

      h6 {
        font-size: 20px;
        color: #333333;
      }

      .social-media-icon {
        width: 19px;
        height: 19px;
        opacity: 1;
      }
    }

    .copyright {
      letter-spacing: 0px;
      color: #969696;
      opacity: 1;
      font-size: 15px;
      // height: 40px;
      // margin-top: 5px;
      // padding: 0;

      // p {
      //   margin: 0;
      // }
    }

    .address {
      width: 60%;
    }
  }
  .footer-menu a, 
.footer-menu Link {
    font-size: 20px;
}
.btn-find-store{
  font-family: "Afacad Flux", serif;
  font-size: "20px";
}

  @media (max-width: 1240px) {
    .footer-container {
      .address {
        width: 90%;
      }
    }
  }

  // .footer-hr {
  //   position: absolute;
  //   bottom: 52px;
  //   margin: 0;
  // }
}

@media (max-width: 820px) {
  // .whatsapp-button {
  //   bottom: 70px;

  //   span {
  //     display: none;
  //   }
  // }
  footer {
    // height: 550px;

    .footer-container {
      padding: 46.18px 20px 5px 20px;

      .logo {
        img {
          width: 73px;
          height: 41px;
        }
      }

      p {
        font-size: 18px;
      }

      a {
        font-size: 18px;
      }

      .footer-heading {
        font-size: 24px;
        margin-top: 50px;
        margin-bottom: 10px !important;
      }

      .social-media-wrapper {
        margin-top: 35px;
      }
    }

  }
}
// @media (max-width: 420px) {
//   footer {
//     height:750px;
   
//   }
// }
.find-store-main-container {
  width: 100%;
  background: #fff;

  .section-contents {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    height: 360px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 0.4rem;

      h4 {
        margin: 0;
        padding: 0;
        text-align: center;
        font: normal normal 600 36px/48px "Vidaloka", serif;
        letter-spacing: 0.54px;
        color: #ffffff;
      }
    }

    .section-info {
      width: 90%;
      text-align: center;
      font: normal normal normal 20px/28px "Afacad Flux", serif;
      color: #ffffff;
      opacity: 1;
    }

    .find-store-change-location {
      width: 65%;
      margin-top: 25px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #999999;
      display: flex;
      justify-content: space-between;

      input {
        text-align: left;
        font: normal normal medium 14px/17px "Afacad Flux", serif;
        letter-spacing: 0px;
        color: #999999;
        opacity: 1;
        border: none;
        outline: none;
      }

      button {
        text-decoration: underline;
        font: normal normal bold 12px/15px "Afacad Flux", serif;
        letter-spacing: 1.8px;
        color: #916f25;
        text-transform: uppercase;
      }
    }
  }

  .store-main-content {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .all-store-titles {
      text-align: left;
      font: normal normal 900 21px/36px "Vidaloka", serif;
      letter-spacing: 3.15px;
      color: #333333;
      text-transform: uppercase;
      opacity: 1;
      padding-bottom: 25px;
    }

    .find-store-google-map-container {
      align-self: stretch;
    }
  }
}

.store-heading{
  font: normal normal normal 20px/24px "Afacad Flux", serif;

}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .store-main-content {
    margin-top: 2rem !important;
  }
  .find-store-main-container .section-title .find-store-change-location {
    width: 95%;
  }
  .find-store-google-map-container {
    height: 400px;
    padding: 0;
  }
}
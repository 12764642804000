.testimonial-section {
  position: relative;
  // background-image: url("../../images/testimonial.png");
  // background-image: url("~images/testimonial.png");
  height: auto;
  margin-top: 100px;
  padding-top: 52px;

  .navigation-icon {
    position: absolute;
    top: 280px;
    right: 20%;
    display: flex;
    grid-gap: 9px;
  }

  .navigation-icon button:hover {
    background: transparent;
    border: none;
  }

  .testimonial-block {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    min-width: 100%;
  }

  .testimonial-wrapper {
    position: relative;
    min-width: 100%;
    height: auto;
    padding-left: 16%;
    // top: 10%;

    .client-img {
      width: 700px;
      height: auto;
    }

    .feedback-wrapper {
      width: 416px;
      padding: 47px 47px 30px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 3px solid #efefef;
      position: absolute;
      left: 704px;
      top: 195px;
      p {
        font: normal normal normal 20px/26px "Afacad Flux", serif !important;
    }
    
      .name-wrapper {
        display: flex;
        align-items: center;
        margin-top: 20px;

        hr {
          border: 1px solid #333333;
          width: 20px;
        }

        .client-name {
          font: italic normal bold 21px/30px "Vidaloka", serif;
          letter-spacing: 0px;
          color: #333333;
          opacity: 1;
          margin-left: 8px;
        }
      }
    }
  }

  ul.testimonial-slider-component::-webkit-scrollbar {
    display: none;
  }

  .testimonial-slider-component {
    padding: 0;
    margin-bottom: 0;
    -ms-overflow-style: none;
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
  }
}
@media (max-width: 1024px) {

.testimonial-wrapper {
  position: relative;
  min-width: 100%;
  height: auto;
  padding-left: 8%;
  
  .feedback-wrapper {
    width: 416px;
    padding: 47px 47px 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 3px solid #efefef;
    position: absolute;
    left: 625px;
    top: 195px;}
  // top: 10%;
}}


@media (max-width: 768px) {
  .testimonial-section {
    // background-image: url("../../images/testimonial1.png");
    // background-image: url("~images/testimonial1.png");
    padding-bottom: 35px;

    .testimonial-wrapper {
      position: static;
      left: auto;
      top: auto;
      min-width: 100%;
      height: auto; 
      margin: 0 20px;
      padding-left: 0%;

      .client-img {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding-left: 0;
      }

      .feedback-wrapper {
        width: 100%;
        padding: 37px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 3px solid #efefef;
        position: unset;
        right: 0;
        p{
        font: normal normal normal 20px/26px "Afacad Flux", serif !important;

        }
      }
    }
  }
}
@media (max-width: 500px) {

  .testimonial-section {
    // background-image: url("../../images/testimonial1.png");
    // background-image: url("~images/testimonial1.png");
    padding-bottom: 35px;


  .testimonial-wrapper {
    position: relative; /* Ensure static positioning */
    min-width: 100%;
    height: auto; /* Adjust height to fit content */
    margin:0%;
    top: unset; /* Remove top positioning */
    }
  }
  }

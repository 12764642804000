.product-catalogues {
  background-color: #fff;

  .product-catalogue-banner {
    position: relative;
    overflow: hidden;
    background-size: cover;
    height: 65vh;

    img {
      width: 100%;
      height: 100%;
    }

    .catalogue-header-wrapper {
      position: absolute;
      bottom: 126px;
      left: 80px;

      h4 {
        font: normal normal 600 60px/80px Playfair Display;
        color: #333333;
        // background: #ffffff 0% 0% no-repeat padding-box;
        // padding: 15px;
        text-transform: capitalize;
      }

      p {
        font: normal normal normal 30px/42px Lato;
        color: #ffffff;
        padding-top: 4px;
      }
    }
  }

  .product-item-wrapper {
    gap: 10px;
  }

  .filter {
    background-color: #333333;
    color: #fff;
  }

  .filter-chip {
    svg {
      color: #fff;
    }
  }

  .product-item-card {
    max-width: 24%;
    :hover{
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    }
    
    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }

    .text {
      position: relative;
      padding-left: 0.4rem;
      top: -80px;
      color: #fff;

      hr {
        border-width: 2px;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .product-catalogues {
    .product-catalogue-banner {
      height: 250px;

      .catalogue-header-wrapper {
        bottom: 16px;
        left: 15px;

        h4 {
          font: normal normal 600 24px / 22px Playfair Display;
          padding: 8px;
        }

        p {
          font: normal normal normal 16px/22px Lato;
        }
      }
    }
    .product-item-card {
      max-width: 46%;
    }
  }
}

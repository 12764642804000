.delivery-wrapper {
  a {
    color: #000;
  }
  .section-background {
    background-color: #f5f5f5;
  }

  .delivery-estimate-section {
    border: 1px solid #000;
    .pincode-section {
      display: flex;
      justify-content: space-between;
    }

    .estimated-delivery {
      .delivery-datetime {
        color: #41962a;
      }
    }
  }
}


.empty-container-section {
  display: none;
}

.image-slider-navigation-icon {
  position: relative;
  top: -28px;
  z-index: 1;
}

.image-slider-component {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  // margin-top: -30px;
}

.favorite-slider {
  grid-gap: 30px;
}
.offer-slider {
  grid-gap: 40px;
}

.round-border {
  border-radius: 50% !important;
  width: 45px !important;
  height: 45px !important;
}
@media (min-width:1024px) {
  .image-slider-component {
   
    margin-top: -30px;
  }
  .image-slider-navigation-icon {
    position: relative;
    top: -60px;
    z-index: 1;
  }
  
}

@media (max-width: 769px) {
  .image-slider-component {
    // flex-direction: row;
    // flex-wrap: wrap;
  // overflow:unset;

  }

  .image-scroll-navigation {
    background: #666666 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
    opacity: 0.5;
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background: #374151;
    border-radius: 50%;
    margin: 5px;
    transition: opacity 100ms ease-out;
    width: 9px;
    height: 9px;
    margin: 4px;
    padding: 5px;
  }
  .image-scroll-navigation:hover {
    border: none;
  }
  .item-section {
    display: grid;
    grid-gap: 4%;
    grid-template-columns: repeat(2, 1fr);
    min-width: 100%;
  }
  .item-section:not(:last-child) {
    margin-right: 9px;
  }
  .offer-slider {
    grid-gap: 20px;
  }
}
